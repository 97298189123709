<template>
  <v-card width="75%" style="margin: 15px auto;">
    <v-toolbar color="primary" dark>
      <v-toolbar-title>Рабочие тех. процессы</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon>
        <v-icon>mdi-cog</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="px-0 pb-0">
      <TmplProcessesTable
        url="manufacture/process-template/index-archive"
        >
        <template v-slot:name="{processTemplate}">
          <router-link  :to="'/manufacture/process-template/view?id=' + processTemplate.id">
            {{processTemplate.name}}
          </router-link>
        </template>
      </TmplProcessesTable>
    </v-card-text>
  </v-card>
</template>

<script>
import TmplProcessesTable from './components/TmplProcessesTable.vue'

export default {
  components: { TmplProcessesTable },
}
</script>
